
import img1 from "./Assets/images/mockup4.png";
import img2 from "./Assets/images/header-shape-1.svg";
import img3 from "./Assets/images/download-shape.svg";
import down from "./Assets/images/mockup3.png";
import about from "./Assets/images/mockup6.png";
import Navbar from './Components/Navbar/Navbar';
import Bango from "./Assets/Bango-App.apk";

function App() {


    return (
        <>
            

            <header className="header-area">

                <Navbar />

                <div id="home" className="header-hero bg_cover d-lg-flex align-items-center">

                    <div className="shape shape-1"></div>
                    <div className="shape shape-2"></div>
                    <div className="shape shape-3"></div>
                    <div className="shape shape-4"></div>
                    <div className="shape shape-5"></div>
                    <div className="shape shape-6"></div>

                    <div className="container">
                        <div className="row align-items-center justify-content-center justify-content-lg-between">
                            <div className="col-lg-6 col-md-10">
                                <div className="header-hero-content">
                                    <h3 className="header-title wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.2s"><span>Ban-go</span> Votre Solution de Financement en Ligne</h3>
                                    <p className="text wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.6s">Vous cherchez un financement rapide et sans tracas pour votre entreprise? Ne cherchez plus! Ban-go est là pour vous aider.</p>
                                    <ul className="d-flex ps-0">
                                        <li><a href="https://mega.nz/file/rP4kGBZI#bA7Ie-Gf5_Us-YGiJYvyuWo4ENBLYkjAXzz9mkltEeI" download="https://mega.nz/file/rP4kGBZI#bA7Ie-Gf5_Us-YGiJYvyuWo4ENBLYkjAXzz9mkltEeI" className="main-btn wow fadeInLeftBig bg-success text-white text-decoration-none" data-wow-duration="1.3s" data-wow-delay="0.8s">Telecharger Bango</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-10">
                                <div className="header-image">
                                    <img src={img1} alt="app" className="image wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.5s" />
                                    <div className="image-shape wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.8s">
                                        <img src={img2} alt="shape" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-shape-1"></div>
                    {/* <div className="header-shape-2">
                        <img src={img} alt="shape" />
                    </div> */}
                </div>
            </header>

            <section id="why" className="services-area pt-110 pb-20">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title text-center pb-25">
                                <h3 className="title">Pourquoi devriez-vous choisir Ban-go</h3>
                                <p className="text">Avec notre plateforme de crédit en ligne, obtenez jusqu'à 100 000 $ ou plus et remboursez en 10 tranches avec seulement 25 % d'intérêt. Plus besoin de garanties ou de cautions. Tout se fait en ligne, de la demande à la signature du contrat.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-services services-color-1 text-center mt-30 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.2s">
                                <div className="services-icon d-flex align-items-center justify-content-center">
                                    <i className="lni lni-layers"></i>
                                </div>
                                <div className="services-content">
                                    <h4 className="services-title"><a href="#">Empruntez Facilement</a></h4>
                                    <p className="text">Obtenez rapidement les fonds dont vous avez besoin sans tracas.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-services services-color-3 text-center mt-30 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.6s">
                                <div className="services-icon d-flex align-items-center justify-content-center">
                                    <i className="lni lni-bolt"></i>
                                </div>
                                <div className="services-content">
                                    <h4 className="services-title"><a href="#">Processus Simplifié</a></h4>
                                    <p className="text">Simplifiez votre demande de prêt avec une procédure rapide et efficace.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-services services-color-4 text-center mt-30 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.8s">
                                <div className="services-icon d-flex align-items-center justify-content-center">
                                    <i className="lni lni-protection"></i>
                                </div>
                                <div className="services-content">
                                    <h4 className="services-title"><a href="#">Transactions Sécurisées</a></h4>
                                    <p className="text">Bénéficiez d'une tranquillité d'esprit totale grâce à nos transactions sécurisées.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="how" className="download-area pt-40 pb-40">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-9">
                            <div className="download-image mt-50 wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.2s">
                                <img className="image" src={down} alt="download" />

                                <div className="download-shape-1"></div>
                                <div className="download-shape-2">
                                    <img className="svg" src={img3} alt="shape" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="download-content mt-45 wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                                <h3 className="download-title">Comment ca marche ?</h3>
                                <p className="text">
                                    1. Téléchargez l'application Ban-go <br /> 2. Remplissez le formulaire de demande en quelques clics <br />3. Payez les frais de localisation de 10 $ à 20 $ <br /> 4. Recevez votre prêt directement sur votre compte <br /> 4. Remboursez en choisissant vos tranches de paiement <br />5. Accédez à votre compte en utilisant votre e-mail et mot de passe</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="download">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-6">
                            <div className="about-content mt-50 wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                                <div className="section-title">
                                    <h3 className="title">Prêt à Emprunter?</h3>
                                    <p className="text">Ne perdez plus une minute! Téléchargez dès maintenant l'application Ban-go pour bénéficier de ses avantages exclusifs. Utilisez le code promo "e2494c54" lors de votre inscription pour accéder à des avantages spéciaux. Après avoir créé un mot de passe sécurisé de 6 chiffres, vous aurez accès à votre compte et pourrez commencer à financer vos projets dès aujourd'hui!<br />
                                    Cliquez sur le lien ci-dessous pour télécharger l'application Ban-go</p>
                                </div>
                                <ul className='ps-0'>

                                    <li>
                                        <a href="https://mega.nz/file/rP4kGBZI#bA7Ie-Gf5_Us-YGiJYvyuWo4ENBLYkjAXzz9mkltEeI" download="https://mega.nz/file/rP4kGBZI#bA7Ie-Gf5_Us-YGiJYvyuWo4ENBLYkjAXzz9mkltEeI" className="main-btn wow fadeInLeftBig bg-success text-white text-decoration-none" data-wow-duration="1.3s" data-wow-delay="0.8s">Telecharger Bango</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image mt-50 wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                                <div className="about-shape"></div>
                                <img className="app" src={about} alt="app" />
                            </div>
                        </div>
                    </div>
                </div>

            </section >

        <footer>
        <div class="footer-copyright">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="copyright d-sm-flex justify-content-between">
                            <div class="copyright-text text-center">
                                <p class="text">Template Crafted by <a rel="nofollow" href="https://uideck.com">UIdeck</a></p>
                            </div> 
                            
                            <div class="copyright-privacy text-center">
                                <a href="#">Bango-App@2024</a>
                            </div> 
                        </div> 
                    </div>
                </div> 
            </div> 
        </div>
        </footer>
        </>
    );
}

export default App;
